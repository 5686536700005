import { default as index6QwOqwqmY8Meta } from "/home/node/app/pages/[category-one]/[category-two]/[category-three]/index.vue?macro=true";
import { default as indexWjL8oaRMy3Meta } from "/home/node/app/pages/[category-one]/[category-two]/index.vue?macro=true";
import { default as indexACCd6OiUBfMeta } from "/home/node/app/pages/[category-one]/index.vue?macro=true";
import { default as about_45uszJf9oZmaDqMeta } from "/home/node/app/pages/about-us.vue?macro=true";
import { default as indexGKv53iih5PMeta } from "/home/node/app/pages/blog/[blog]/index.vue?macro=true";
import { default as indexAZF5e672fWMeta } from "/home/node/app/pages/blog/index.vue?macro=true";
import { default as contactHKkWCgEiHwMeta } from "/home/node/app/pages/contact.vue?macro=true";
import { default as documentsVdUdpvrEZqMeta } from "/home/node/app/pages/documents.vue?macro=true";
import { default as indexca63Ho8mnZMeta } from "/home/node/app/pages/index.vue?macro=true";
import { default as information_45requestaL6o5x3NmxMeta } from "/home/node/app/pages/information-request.vue?macro=true";
import { default as indexeTvAb1ApB4Meta } from "/home/node/app/pages/patient-guide/[slug]/[subslug]/index.vue?macro=true";
import { default as indexUaNBJ4lrsfMeta } from "/home/node/app/pages/patient-guide/[slug]/index.vue?macro=true";
import { default as indexjvRh3aW279Meta } from "/home/node/app/pages/patient-guide/index.vue?macro=true";
import { default as reviewsfGI3JzxjUPMeta } from "/home/node/app/pages/reviews.vue?macro=true";
import { default as component_45stubVU3eVV14UaMeta } from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubVU3eVV14Ua } from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "categoryone-categorytwo-categorythree___en",
    path: "/en/:categoryone()/:categorytwo()/:categorythree()",
    meta: index6QwOqwqmY8Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/[category-three]/index.vue")
  },
  {
    name: "categoryone-categorytwo-categorythree___tr",
    path: "/:categoryone()/:categorytwo()/:categorythree()",
    meta: index6QwOqwqmY8Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/[category-three]/index.vue")
  },
  {
    name: "categoryone-categorytwo-categorythree___de",
    path: "/de/:categoryone()/:categorytwo()/:categorythree()",
    meta: index6QwOqwqmY8Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/[category-three]/index.vue")
  },
  {
    name: "categoryone-categorytwo-categorythree___es",
    path: "/es/:categoryone()/:categorytwo()/:categorythree()",
    meta: index6QwOqwqmY8Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/[category-three]/index.vue")
  },
  {
    name: "categoryone-categorytwo-categorythree___ru",
    path: "/ru/:categoryone()/:categorytwo()/:categorythree()",
    meta: index6QwOqwqmY8Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/[category-three]/index.vue")
  },
  {
    name: "categoryone-categorytwo-categorythree___it",
    path: "/it/:categoryone()/:categorytwo()/:categorythree()",
    meta: index6QwOqwqmY8Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/[category-three]/index.vue")
  },
  {
    name: "categoryone-categorytwo___en",
    path: "/en/:categoryone()/:categorytwo()",
    meta: indexWjL8oaRMy3Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/index.vue")
  },
  {
    name: "categoryone-categorytwo___tr",
    path: "/:categoryone()/:categorytwo()",
    meta: indexWjL8oaRMy3Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/index.vue")
  },
  {
    name: "categoryone-categorytwo___de",
    path: "/de/:categoryone()/:categorytwo()",
    meta: indexWjL8oaRMy3Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/index.vue")
  },
  {
    name: "categoryone-categorytwo___es",
    path: "/es/:categoryone()/:categorytwo()",
    meta: indexWjL8oaRMy3Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/index.vue")
  },
  {
    name: "categoryone-categorytwo___ru",
    path: "/ru/:categoryone()/:categorytwo()",
    meta: indexWjL8oaRMy3Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/index.vue")
  },
  {
    name: "categoryone-categorytwo___it",
    path: "/it/:categoryone()/:categorytwo()",
    meta: indexWjL8oaRMy3Meta || {},
    component: () => import("/home/node/app/pages/[category-one]/[category-two]/index.vue")
  },
  {
    name: "categoryone___en",
    path: "/en/:categoryone()",
    meta: indexACCd6OiUBfMeta || {},
    component: () => import("/home/node/app/pages/[category-one]/index.vue")
  },
  {
    name: "categoryone___tr",
    path: "/:categoryone()",
    meta: indexACCd6OiUBfMeta || {},
    component: () => import("/home/node/app/pages/[category-one]/index.vue")
  },
  {
    name: "categoryone___de",
    path: "/de/:categoryone()",
    meta: indexACCd6OiUBfMeta || {},
    component: () => import("/home/node/app/pages/[category-one]/index.vue")
  },
  {
    name: "categoryone___es",
    path: "/es/:categoryone()",
    meta: indexACCd6OiUBfMeta || {},
    component: () => import("/home/node/app/pages/[category-one]/index.vue")
  },
  {
    name: "categoryone___ru",
    path: "/ru/:categoryone()",
    meta: indexACCd6OiUBfMeta || {},
    component: () => import("/home/node/app/pages/[category-one]/index.vue")
  },
  {
    name: "categoryone___it",
    path: "/it/:categoryone()",
    meta: indexACCd6OiUBfMeta || {},
    component: () => import("/home/node/app/pages/[category-one]/index.vue")
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    meta: about_45uszJf9oZmaDqMeta || {},
    component: () => import("/home/node/app/pages/about-us.vue")
  },
  {
    name: "about-us___tr",
    path: "/hakkimizda",
    meta: about_45uszJf9oZmaDqMeta || {},
    component: () => import("/home/node/app/pages/about-us.vue")
  },
  {
    name: "about-us___de",
    path: "/de/about-us",
    meta: about_45uszJf9oZmaDqMeta || {},
    component: () => import("/home/node/app/pages/about-us.vue")
  },
  {
    name: "about-us___es",
    path: "/es/about-us",
    meta: about_45uszJf9oZmaDqMeta || {},
    component: () => import("/home/node/app/pages/about-us.vue")
  },
  {
    name: "about-us___ru",
    path: "/ru/about-us",
    meta: about_45uszJf9oZmaDqMeta || {},
    component: () => import("/home/node/app/pages/about-us.vue")
  },
  {
    name: "about-us___it",
    path: "/it/about-us",
    meta: about_45uszJf9oZmaDqMeta || {},
    component: () => import("/home/node/app/pages/about-us.vue")
  },
  {
    name: "blog-blog___en",
    path: "/en/blog/:blog()",
    meta: indexGKv53iih5PMeta || {},
    component: () => import("/home/node/app/pages/blog/[blog]/index.vue")
  },
  {
    name: "blog-blog___tr",
    path: "/blog/:blog()",
    meta: indexGKv53iih5PMeta || {},
    component: () => import("/home/node/app/pages/blog/[blog]/index.vue")
  },
  {
    name: "blog-blog___de",
    path: "/de/blog/:blog()",
    meta: indexGKv53iih5PMeta || {},
    component: () => import("/home/node/app/pages/blog/[blog]/index.vue")
  },
  {
    name: "blog-blog___es",
    path: "/es/blog/:blog()",
    meta: indexGKv53iih5PMeta || {},
    component: () => import("/home/node/app/pages/blog/[blog]/index.vue")
  },
  {
    name: "blog-blog___ru",
    path: "/ru/blog/:blog()",
    meta: indexGKv53iih5PMeta || {},
    component: () => import("/home/node/app/pages/blog/[blog]/index.vue")
  },
  {
    name: "blog-blog___it",
    path: "/it/blog/:blog()",
    meta: indexGKv53iih5PMeta || {},
    component: () => import("/home/node/app/pages/blog/[blog]/index.vue")
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/home/node/app/pages/blog/index.vue")
  },
  {
    name: "blog___tr",
    path: "/blog",
    component: () => import("/home/node/app/pages/blog/index.vue")
  },
  {
    name: "blog___de",
    path: "/de/blog",
    component: () => import("/home/node/app/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/home/node/app/pages/blog/index.vue")
  },
  {
    name: "blog___ru",
    path: "/ru/blog",
    component: () => import("/home/node/app/pages/blog/index.vue")
  },
  {
    name: "blog___it",
    path: "/it/blog",
    component: () => import("/home/node/app/pages/blog/index.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    meta: contactHKkWCgEiHwMeta || {},
    component: () => import("/home/node/app/pages/contact.vue")
  },
  {
    name: "contact___tr",
    path: "/iletisim",
    meta: contactHKkWCgEiHwMeta || {},
    component: () => import("/home/node/app/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/contact",
    meta: contactHKkWCgEiHwMeta || {},
    component: () => import("/home/node/app/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contact",
    meta: contactHKkWCgEiHwMeta || {},
    component: () => import("/home/node/app/pages/contact.vue")
  },
  {
    name: "contact___ru",
    path: "/ru/contact",
    meta: contactHKkWCgEiHwMeta || {},
    component: () => import("/home/node/app/pages/contact.vue")
  },
  {
    name: "contact___it",
    path: "/it/contact",
    meta: contactHKkWCgEiHwMeta || {},
    component: () => import("/home/node/app/pages/contact.vue")
  },
  {
    name: "documents___en",
    path: "/en/documents",
    meta: documentsVdUdpvrEZqMeta || {},
    component: () => import("/home/node/app/pages/documents.vue")
  },
  {
    name: "documents___tr",
    path: "/dokumanlar",
    meta: documentsVdUdpvrEZqMeta || {},
    component: () => import("/home/node/app/pages/documents.vue")
  },
  {
    name: "documents___de",
    path: "/de/documents",
    meta: documentsVdUdpvrEZqMeta || {},
    component: () => import("/home/node/app/pages/documents.vue")
  },
  {
    name: "documents___es",
    path: "/es/documents",
    meta: documentsVdUdpvrEZqMeta || {},
    component: () => import("/home/node/app/pages/documents.vue")
  },
  {
    name: "documents___ru",
    path: "/ru/documents",
    meta: documentsVdUdpvrEZqMeta || {},
    component: () => import("/home/node/app/pages/documents.vue")
  },
  {
    name: "documents___it",
    path: "/it/documents",
    meta: documentsVdUdpvrEZqMeta || {},
    component: () => import("/home/node/app/pages/documents.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/home/node/app/pages/index.vue")
  },
  {
    name: "index___tr",
    path: "/",
    component: () => import("/home/node/app/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/home/node/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/home/node/app/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/home/node/app/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/home/node/app/pages/index.vue")
  },
  {
    name: "information-request___en",
    path: "/en/information-request",
    meta: information_45requestaL6o5x3NmxMeta || {},
    component: () => import("/home/node/app/pages/information-request.vue")
  },
  {
    name: "information-request___tr",
    path: "/bilgi-istek",
    meta: information_45requestaL6o5x3NmxMeta || {},
    component: () => import("/home/node/app/pages/information-request.vue")
  },
  {
    name: "information-request___de",
    path: "/de/information-request",
    meta: information_45requestaL6o5x3NmxMeta || {},
    component: () => import("/home/node/app/pages/information-request.vue")
  },
  {
    name: "information-request___es",
    path: "/es/information-request",
    meta: information_45requestaL6o5x3NmxMeta || {},
    component: () => import("/home/node/app/pages/information-request.vue")
  },
  {
    name: "information-request___ru",
    path: "/ru/information-request",
    meta: information_45requestaL6o5x3NmxMeta || {},
    component: () => import("/home/node/app/pages/information-request.vue")
  },
  {
    name: "information-request___it",
    path: "/it/information-request",
    meta: information_45requestaL6o5x3NmxMeta || {},
    component: () => import("/home/node/app/pages/information-request.vue")
  },
  {
    name: "patient-guide-slug-subslug___en",
    path: "/en/patient-guide/:slug()/:subslug()",
    meta: indexeTvAb1ApB4Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/[subslug]/index.vue")
  },
  {
    name: "patient-guide-slug-subslug___tr",
    path: "/hasta-rehberi/:slug()/:subslug()",
    meta: indexeTvAb1ApB4Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/[subslug]/index.vue")
  },
  {
    name: "patient-guide-slug-subslug___de",
    path: "/de/patient-guide/:slug()/:subslug()",
    meta: indexeTvAb1ApB4Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/[subslug]/index.vue")
  },
  {
    name: "patient-guide-slug-subslug___es",
    path: "/es/patient-guide/:slug()/:subslug()",
    meta: indexeTvAb1ApB4Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/[subslug]/index.vue")
  },
  {
    name: "patient-guide-slug-subslug___ru",
    path: "/ru/patient-guide/:slug()/:subslug()",
    meta: indexeTvAb1ApB4Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/[subslug]/index.vue")
  },
  {
    name: "patient-guide-slug-subslug___it",
    path: "/it/patient-guide/:slug()/:subslug()",
    meta: indexeTvAb1ApB4Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/[subslug]/index.vue")
  },
  {
    name: "patient-guide-slug___en",
    path: "/en/patient-guide/:slug()",
    meta: indexUaNBJ4lrsfMeta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/index.vue")
  },
  {
    name: "patient-guide-slug___tr",
    path: "/hasta-rehberi/:slug()",
    meta: indexUaNBJ4lrsfMeta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/index.vue")
  },
  {
    name: "patient-guide-slug___de",
    path: "/de/patient-guide/:slug()",
    meta: indexUaNBJ4lrsfMeta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/index.vue")
  },
  {
    name: "patient-guide-slug___es",
    path: "/es/patient-guide/:slug()",
    meta: indexUaNBJ4lrsfMeta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/index.vue")
  },
  {
    name: "patient-guide-slug___ru",
    path: "/ru/patient-guide/:slug()",
    meta: indexUaNBJ4lrsfMeta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/index.vue")
  },
  {
    name: "patient-guide-slug___it",
    path: "/it/patient-guide/:slug()",
    meta: indexUaNBJ4lrsfMeta || {},
    component: () => import("/home/node/app/pages/patient-guide/[slug]/index.vue")
  },
  {
    name: "patient-guide___en",
    path: "/en/patient-guide",
    meta: indexjvRh3aW279Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/index.vue")
  },
  {
    name: "patient-guide___tr",
    path: "/hasta-rehberi",
    meta: indexjvRh3aW279Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/index.vue")
  },
  {
    name: "patient-guide___de",
    path: "/de/patient-guide",
    meta: indexjvRh3aW279Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/index.vue")
  },
  {
    name: "patient-guide___es",
    path: "/es/patient-guide",
    meta: indexjvRh3aW279Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/index.vue")
  },
  {
    name: "patient-guide___ru",
    path: "/ru/patient-guide",
    meta: indexjvRh3aW279Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/index.vue")
  },
  {
    name: "patient-guide___it",
    path: "/it/patient-guide",
    meta: indexjvRh3aW279Meta || {},
    component: () => import("/home/node/app/pages/patient-guide/index.vue")
  },
  {
    name: "reviews___en",
    path: "/en/reviews",
    meta: reviewsfGI3JzxjUPMeta || {},
    component: () => import("/home/node/app/pages/reviews.vue")
  },
  {
    name: "reviews___tr",
    path: "/yorumlar",
    meta: reviewsfGI3JzxjUPMeta || {},
    component: () => import("/home/node/app/pages/reviews.vue")
  },
  {
    name: "reviews___de",
    path: "/de/reviews",
    meta: reviewsfGI3JzxjUPMeta || {},
    component: () => import("/home/node/app/pages/reviews.vue")
  },
  {
    name: "reviews___es",
    path: "/es/reviews",
    meta: reviewsfGI3JzxjUPMeta || {},
    component: () => import("/home/node/app/pages/reviews.vue")
  },
  {
    name: "reviews___ru",
    path: "/ru/reviews",
    meta: reviewsfGI3JzxjUPMeta || {},
    component: () => import("/home/node/app/pages/reviews.vue")
  },
  {
    name: "reviews___it",
    path: "/it/reviews",
    meta: reviewsfGI3JzxjUPMeta || {},
    component: () => import("/home/node/app/pages/reviews.vue")
  },
  {
    name: component_45stubVU3eVV14UaMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubVU3eVV14Ua
  },
  {
    name: component_45stubVU3eVV14UaMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubVU3eVV14Ua
  },
  {
    name: component_45stubVU3eVV14UaMeta?.name,
    path: "/en-sitemap.xml",
    component: component_45stubVU3eVV14Ua
  },
  {
    name: component_45stubVU3eVV14UaMeta?.name,
    path: "/tr-sitemap.xml",
    component: component_45stubVU3eVV14Ua
  },
  {
    name: component_45stubVU3eVV14UaMeta?.name,
    path: "/de-sitemap.xml",
    component: component_45stubVU3eVV14Ua
  },
  {
    name: component_45stubVU3eVV14UaMeta?.name,
    path: "/es-sitemap.xml",
    component: component_45stubVU3eVV14Ua
  },
  {
    name: component_45stubVU3eVV14UaMeta?.name,
    path: "/ru-sitemap.xml",
    component: component_45stubVU3eVV14Ua
  },
  {
    name: component_45stubVU3eVV14UaMeta?.name,
    path: "/it-sitemap.xml",
    component: component_45stubVU3eVV14Ua
  }
]