import { contentTypes as _contentTypes } from '~/enums'

interface UserState {
  headerHeight: number
  routerHeight: number
  mobileMenu: boolean
  headerHide: boolean
  services: any
  servicesTree: any
  activeService: any
  patientGuideTree: any
  activeLocalizations: any
  time: any
}

export const useUserStore = defineStore({
  id: 'user',
  state: (): UserState => ({
    headerHeight: 0,
    headerHide: false,
    routerHeight: 0,
    mobileMenu: false,
    services: {},
    servicesTree: {},
    activeService: undefined,
    patientGuideTree: {},
    activeLocalizations: undefined,
    time: '',
  }),
  getters: {
    getServices(state: any) {
      const nuxtapp = useNuxtApp()
      const { locale } = nuxtapp.$i18n
      return state.services[locale.value]
    },
    getServicesTree(state: any) {
      const nuxtapp = useNuxtApp()
      const { locale } = nuxtapp.$i18n
      return state.servicesTree[locale.value]
    },
    getPatientGuideTree(state: any) {
      const nuxtapp = useNuxtApp()
      const { locale } = nuxtapp.$i18n
      return state.patientGuideTree[locale.value]
    },
  },
  actions: {
    async setServices(locale: any) {
      if (this.services?.[locale]) return
      try {
        const { find } = useStrapi()
        const categories = (await find('get-service-nav', {
          locale,
        })) as any
        
        this.services[locale] = categories.services || []
        this.servicesTree[locale] = categories.tree || []
        
      } catch (error) {}
    },
    async setPatientGuide(locale: any) {
      if (this.patientGuideTree?.[locale]) return
      try {
        const { find } = useStrapi()

        const { data } = (await find('patient-guides', {
          fields: ['slug', 'firstPage'],
          populate: {
            localizations: {
              fields: ['slug', 'locale'],
            },
            categories: {
              fields: ['slug'],
              populate: {
                localizations: {
                  fields: ['slug', 'locale'],
                },
              },
            },
          },
          locale,
        })) as any

        const localePath = useLocalePath()
        const pageName = localePath('patient-guide', locale).replace(`/${locale}/`, '/')
        const patientGuide = [] as any

        data.forEach((item: any) => {
          const obj = {
            slug: item?.attributes?.slug,
            link: item?.attributes?.firstPage ? pageName : `${pageName}/${item?.attributes?.slug}`,
            localizations: {},
            parent: null,
          } as any

          obj.localizations[locale] = { slug: obj.slug, link: obj.link }
          item?.attributes?.localizations?.data?.forEach((_item: any) => {
            obj.localizations[_item?.attributes?.locale] = {
              slug: _item?.attributes?.slug,
              link: item?.attributes?.firstPage
                ? '/' + _item?.attributes?.slug
                : localePath('patient-guide', _item?.attributes?.locale).replace(`/${locale}/`, '/') + '/' + _item?.attributes?.slug,
            }
          })

          patientGuide.push(obj)

          item?.attributes?.categories?.data?.forEach((subitem: any) => {
            const obj2 = {
              slug: subitem?.attributes?.slug,
              link: `${obj.link}/${subitem?.attributes?.slug}`,
              localizations: {},
              parent: { ...obj.localizations },
            } as any

            obj2.localizations[locale] = { slug: obj2.slug, link: obj2.link }
            subitem?.attributes?.localizations?.data?.forEach((_item: any) => {
              obj2.localizations[_item?.attributes?.locale] = {
                slug: _item?.attributes?.slug,
                link: obj?.localizations?.[_item?.attributes?.locale]?.link + '/' + _item?.attributes?.slug,
              }
            })
            patientGuide.push(obj2)
          })
        })
        this.patientGuideTree[locale] = patientGuide
      } catch (error) {}
    },
    deactivateNestedObject(obj: any) {
      if (obj && typeof obj === 'object') {
        obj.active = false

        if (Array.isArray(obj.children)) {
          obj.children.forEach((child: any) => this.deactivateNestedObject(child))
        }
      }
    },
    getServiceWithSlug(slug: any, locale: any) {
      const data = JSON.parse(JSON.stringify(this.servicesTree[locale]))
      return data.find((item: any) => item.slugs.includes(slug))
    },
    getServiceWithPath(link: any, locale: any) {
      const data = JSON.parse(JSON.stringify(this.servicesTree[locale]))
      return data.find((item: any) => Object.values(item?.localizations)?.some((subitem: any) => subitem?.link === link))
    },
    getGuideWithPath(link: any, locale: any) {
      const data = JSON.parse(JSON.stringify(this.patientGuideTree[locale]))
      return data.find((item: any) => Object.values(item?.localizations)?.some((subitem: any) => subitem?.link === link))
    },
  },
})
