import { Field, configure, defineRule } from 'vee-validate'
import * as AllRules from '@vee-validate/rules'
import { localize } from '@vee-validate/i18n'

export default defineNuxtPlugin((nuxtApp) => {
  const allRules = AllRules as any
  const veeValidateRules = allRules?.all || AllRules
  if (Object.keys(veeValidateRules).length) {
    Object.keys(veeValidateRules).forEach((rule) => {
      if (rule !== 'default') {
        defineRule(rule, veeValidateRules[rule])
      }
    })
  }
  const lowerCaseRegex = '?=.*[a-z]'
  const upperCaseRegex = '?=.*[A-Z]'
  const digitRegex = '?=.*d'
  const nonWordRegex = '?=.*W'

  defineRule('RequireDigit', (value: any) => {
    return value.matches(digitRegex)
  })

  defineRule('RequireLowercase', (value: any) => {
    return value.matches(lowerCaseRegex)
  })
  defineRule('RequireNonAlphanumeric', (value: any) => {
    return value.matches(nonWordRegex)
  })
  defineRule('RequireUppercase', (value: any) => {
    return value.matches(upperCaseRegex)
  })
  defineRule('RequiredLength', (value: any) => {
    return value.matches('.*\\d+.*')
  })

  defineRule('vselect', (value: any) => {
    return value !== undefined && value !== null && Object.keys(value).length > 0
  })
  defineRule('confirm', (value, target, data) => {
    let isSame = true
    target.forEach((a: any) => {
      value === data.form[a] ? (isSame = true) : (isSame = false)
    })
    return value !== undefined && value !== null && Object.keys(value).length > 0 && isSame
  })

  const dic = {
    en: {
      messages: {
        alpha: 'The "{field}" field may only contain alphabetic characters',
        alpha_num: 'The "{field}" field may only contain alpha-numeric characters',
        alpha_dash: 'The "{field}" field may contain alpha-numeric characters as well as dashes and underscores',
        alpha_spaces: 'The "{field}" field may only contain alphabetic characters as well as spaces',
        between: 'The "{field}" field must be between {min} and {max}',
        confirmed: 'The "{field}" field confirmation does not match',
        digits: 'The "{field}" field must be numeric and exactly contain {length} digits',
        dimensions: 'The "{field}" field must be {width} pixels by {height} pixels',
        email: 'The "{field}" field must be a valid email',
        excluded: 'The "{field}" field is not a valid value',
        ext: 'The "{field}" field is not a valid file',
        image: 'The "{field}" field must be an image',
        integer: 'The "{field}" field must be an integer',
        length: 'The "{field}" field must be {length} long',
        max_value: 'The "{field}" field must be {max} or less',
        max: 'The "{field}" field may not be greater than {length} characters',
        mimes: 'The "{field}" field must have a valid file type',
        min_value: 'The "{field}" field must be {min} or more',
        min: 'The "{field}" field must be at least {length} characters',
        numeric: 'The "{field}" field may only contain numeric characters',
        oneOf: 'The "{field}" field is not a valid value',
        regex: 'The "{field}" field format is invalid',
        required_if: 'The "{field}" field is required',
        required: 'The "{field}" field is required',
        size: 'The "{field}" field size must be less than {size}KB',
        double: 'The "{field}" field must be a valid decimal',
        not_register: 'named client not register',
      },
    },
    vi: {
      messages: {
        alpha: 'Trường "{field}" chỉ có thể chứa ký tự chữ cái',
        alpha_num: 'Trường "{field}" chỉ có thể chứa ký tự chữ cái và số',
        alpha_dash: 'Trường "{field}" có thể chứa ký tự chữ cái, số, dấu gạch ngang và gạch dưới',
        alpha_spaces: 'Trường "{field}" chỉ có thể chứa ký tự chữ cái và khoảng trắng',
        between: 'Trường "{field}" phải nằm trong khoảng từ {min} đến {max}',
        confirmed: 'Trường "{field}" không khớp với trường xác nhận',
        digits: 'Trường "{field}" phải là số và có đúng {length} chữ số',
        dimensions: 'Trường "{field}" phải có kích thước {width} pixel x {height} pixel',
        email: 'Trường "{field}" phải là một địa chỉ email hợp lệ',
        excluded: 'Trường "{field}" không phải là một giá trị hợp lệ',
        ext: 'Trường "{field}" không phải là một tệp tin hợp lệ',
        image: 'Trường "{field}" phải là một hình ảnh',
        integer: 'Trường "{field}" phải là một số nguyên',
        length: 'Trường "{field}" phải có độ dài {length}',
        max_value: 'Trường "{field}" phải nhỏ hơn hoặc bằng {max}',
        max: 'Trường "{field}" không được dài hơn {length} ký tự',
        mimes: 'Trường "{field}" phải có định dạng tệp tin hợp lệ',
        min_value: 'Trường "{field}" phải lớn hơn hoặc bằng {min}',
        min: 'Trường "{field}" phải có ít nhất {length} ký tự',
        numeric: 'Trường "{field}" chỉ có thể chứa ký tự số',
        oneOf: 'Trường "{field}" không phải là một giá trị hợp lệ',
        regex: 'Trường "{field}" có định dạng không hợp lệ',
        required_if: 'Trường "{field}" là bắt buộc',
        required: 'Trường "{field}" là bắt buộc',
        size: 'Kích thước của trường "{field}" phải nhỏ hơn {size}KB',
        double: 'Trường "{field}" phải là một số thập phân hợp lệ',
        not_register: 'Tên khách hàng chưa đăng ký',
      },
    },
  }
  configure({
    generateMessage: localize(dic),
  })

  nuxtApp.vueApp.component('Field', Field)
})
